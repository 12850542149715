import React from 'react';
import { Button, Message } from '@arco-design/web-react';
import '@arco-design/web-react/dist/css/arco.css';
import Menu from './components/menu'
import Alert from './components/alert'
import Badge from './components/badge'
import Drawer from './components/Drawer'
import Comment from './components/Comment'
import Calendar from './components/Calendar'
import Mark from "./components/Mark";

function App() {
  const handleClick = () => {
    Message.success('You clicked the button!');
  };

  return (
      <div style={{ padding: 20, maxWidth: "80%", margin: 'auto' }}>
        {/*<Button type="primary" onClick={handleClick}>*/}
        {/*  Click me!*/}
        {/*</Button>*/}
        <Menu />
        <br />
        <Alert />
        <br />
        <Badge />
        <br /><br />
        <Drawer />
        <br />
        <p>我的生日</p>
        <Calendar />
        <br />
          <p>给我评个分吧</p>
          <Mark/>
        <p>评论</p>
        <Comment />
      </div>
  );
}

export default App;