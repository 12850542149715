import { Rate, Typography } from '@arco-design/web-react';
import { useState } from 'react';

function App() {
    const [rate, setRate] = useState(5);
    const desc = ['Terrible', 'Bad', '一般', '好', '非常好'];
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <Rate value={rate} onChange={(value) => setRate(value)} />
            <Typography.Text
                style={{
                    margin: '0 16px',
                }}
            >
                {desc[rate - 1]}
            </Typography.Text>
        </div>
    );
}

export default App;
