import {PageHeader, Message, Radio, Button} from '@arco-design/web-react';

const App = () => {
    return (
            <PageHeader
                style={{ background: 'var(--color-bg-2)' }}
                title='damesck的联系方式'
                subTitle='孙亚伟'
                extra={
                    <div>
                        <Radio.Group mode='fill' type='button' defaultValue='small'>
                            <Button type='outline' href={'https://blog.damesck.net/'}>返回</Button>
                        </Radio.Group>
                    </div>
                }
            />
    );
};

export default App;
