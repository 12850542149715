import { useState } from 'react';
import { Drawer, Button, Divider, Link, Descriptions } from '@arco-design/web-react';
import { Image } from '@arco-design/web-react';

function App() {
    const [visible, setVisible] = useState(false);
    return (
        <div>
            <Button
                onClick={() => {
                    setVisible(true);
                }}
                type='primary'
            >
                社交平台
            </Button>
            <Drawer
                width={350}
                title={<span>damesck的社交平台 </span>}
                visible={visible}
                onOk={() => {
                    setVisible(false);
                }}
                onCancel={() => {
                    setVisible(false);
                }}
                footer={null}
            >
                <Descriptions
                    colon=''
                    title='微信&QQ'
                    column={1}
                    labelStyle={{ width: 100 }}
                    data={[
                        {
                            label: '微信号',
                            value: 'damesck',
                        },
                        {
                            label: 'QQ',
                            value: <Button type='primary' size='mini' href={'https://wpa.qq.com/msgrd?V=3&uin=2449813874'}>
                                点击按钮添加
                            </Button>,
                        },
                        {
                            label: '企业微信',
                            value: <Button type='primary' size='mini' status='success' href={'https://api.damesck.net/api/picture/contact/damesckqywx.png'}>
                                点击扫码添加
                            </Button>,
                        },
                        {
                            label: <Image
                                width={200}
                                src='https://api.damesck.net/api/picture/contact/damesckwx.png'
                                alt='lamp'
                            />,
                        },
                    ]}
                />
                <Divider />
                <Descriptions
                    colon=''
                    title='其他平台'
                    column={1}
                    labelStyle={{ width: 100 }}
                    data={[
                        // {
                        //     label: 'X',
                        //     value: <Button type='primary' size='mini' href={'https://x.com/damesck'}>
                        //         点击按钮查看
                        //     </Button>,
                        // },
                        {
                            label: 'GitHub',
                            value: <Button type='primary' size='mini' href={'https://github.com/damesck233'}>
                                点击按钮查看
                            </Button>,
                        },
                    ]}
                />
            </Drawer>
        </div>
    );
}

export default App;
