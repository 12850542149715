import { Calendar } from '@arco-design/web-react';

const App = () => {
    return (
        <div style={{ width: '100%', overflow: 'auto' }}>
            <Calendar defaultValue='2006-01-07' />
        </div>
    );
};

export default App;
